<template>
<form @submit.prevent="">
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان فهرست" id="favorite-title" v-model="favorite.title"></v-text-field>
        <div v-if="!isMobile" class="col-12">
            <input type="text" id="favorite-title" placeholder="عنوان فهرست" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="favorite.title">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary rename-favorite"><i class="uil uil-save"></i> ذخیره تغییرات</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مودال ویرایش علاقه مندی ها می باشد
export default {
    name: 'FavoriteEdit',
    props: ['data'],
    components: {},
    data: function () {
        return {
            favorite: {
                title: '',
            },
            isMobile:false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.favorite = this.data.favorite;
        },
        // این متد برای ذخیره سازی اطلاعات استفاده میشود
        saveChanges() {
            if (this.favorite.title) {
                this.$parent.emitData('editFavorite', { favorite: this.favorite }, true);
            }
        },
    },
}
</script>
